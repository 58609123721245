import React, { useMemo } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Paper,
  Box,
  Divider,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OrderLineItem from './OrderLineItem'; // import the line item component
import {
  convertCurrencyToFloat,
  convertFloatToCurrency,
} from '../../_GlobalFunctions/FormatCurrency';

const OrderAccordion = React.memo(({ order, selectedItems, onCheckboxChange }) => {
  // Build a lookup for selected items in this invoice.
  const orderSelectedLookup = useMemo(() => {
    const lookup = {};
    const items = Object.values(order.LINE_ITEMS);
    items.forEach((item) => {
      const key = `${order.INVOICE_NUMBER}-${item.ITEM_NUMBER}`;
      if (selectedItems[key]) {
        lookup[item.ITEM_NUMBER] = true;
      }
    });
    return lookup;
  }, [selectedItems, order]);

  return (
    <Accordion
      sx={{
        border: '2px solid transparent',
        borderRadius: 2,
        overflow: 'hidden',
        boxShadow: 3,
        mb: 2,
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        '&.Mui-expanded': {
          borderColor: (theme) => theme.palette.tsw.tertiary,
          margin: 0,
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: 'inherit', fontSize: 28 }} />}
        sx={{
          backgroundColor: 'white',
          color: 'black',
          px: 2,
          py: 1.5,
          transition: 'all 0.3s ease',
          '&.Mui-expanded': {
            backgroundColor: (theme) => theme.palette.tsw.tertiary,
            color: 'white',
            '& .MuiAccordionSummary-expandIconWrapper': {
              color: 'white',
            },
          },
        }}
      >
        <Box width='100%'>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={3}>
              <Typography variant='subtitle2' fontWeight='bold'>
                Invoice #
              </Typography>
              <Typography variant='body1'>{order.INVOICE_NUMBER}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant='subtitle2' fontWeight='bold'>
                PO #
              </Typography>
              <Typography variant='body1'>{order.PO_NUMBER}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant='subtitle2' fontWeight='bold'>
                Date Ordered:
              </Typography>
              <Typography variant='body1'>{order.INVOICE_DATE}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant='subtitle2' fontWeight='bold'>
                Order Total:
              </Typography>
              <Typography variant='body1'>
                {convertFloatToCurrency(convertCurrencyToFloat(order.INVOICE_NET_TOTAL))}
              </Typography>
            </Grid>
          </Grid>
          {Number(order.FULFILLMENT_ORDER) === 1 && (
            <Box mt={1}>
              <Typography
                variant='body2'
                fontWeight='bold'
                sx={{ color: (theme) => theme.palette.tsw.secondary }}
              >
                Fulfillment Order
              </Typography>
            </Box>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          p: 3,
          borderRadius: 2,
          backgroundColor: 'white',
        }}
      >
        <Typography
          variant='h6'
          sx={{
            mb: 2,
            fontWeight: 'bold',
            color: (theme) => theme.palette.tsw.tertiary,
          }}
        >
          Order Items
        </Typography>
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.grey[200],
            p: 1,
            borderRadius: 1,
            mb: 2,
          }}
        >
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={1} textAlign='center'>
              <Typography variant='subtitle2' fontWeight='bold'>
                Select
              </Typography>
            </Grid>
            <Grid item xs={1} textAlign='center'>
              <Typography variant='subtitle2' fontWeight='bold'>
                Qty
              </Typography>
            </Grid>
            <Grid item xs={2} textAlign='center'>
              <Typography variant='subtitle2' fontWeight='bold'>
                UPC
              </Typography>
            </Grid>
            <Grid item xs={1} textAlign='center'>
              <Typography variant='subtitle2' fontWeight='bold'>
                MPN
              </Typography>
            </Grid>
            <Grid item xs={2} textAlign='center'>
              <Typography variant='subtitle2' fontWeight='bold'>
                Item #
              </Typography>
            </Grid>
            <Grid item xs={4} textAlign='center'>
              <Typography variant='subtitle2' fontWeight='bold'>
                Description
              </Typography>
            </Grid>
            <Grid item xs={1} textAlign='center'>
              <Typography variant='subtitle2' fontWeight='bold'>
                Price
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {/* <Divider sx={{ mb: 2 }} /> */}
        {Object.values(order.LINE_ITEMS).map((item) => {
          const key = `${order.INVOICE_NUMBER}-${item.ITEM_NUMBER}`;
          const isChecked = !!orderSelectedLookup[item.ITEM_NUMBER];
          return (
            <OrderLineItem
              key={item.ITEM_NUMBER}
              order={order}
              item={item}
              isChecked={isChecked}
              onCheckboxChange={onCheckboxChange}
            />
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
});

export default OrderAccordion;
