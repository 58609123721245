import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Grid,
  Paper,
  IconButton,
  Button,
  Select,
  MenuItem,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Divider,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { resetReturnObject, prevReturnStep, resetReturnStep } from '../../../Redux/ReturnSlice';
import FileSelect from './FileSelect';
import { useTheme } from '@mui/material/styles';
import {
  convertCurrencyToFloat,
  convertFloatToCurrency,
} from '../../_GlobalFunctions/FormatCurrency';

const ReturnSummary = () => {
  const [reasons, setReasons] = useState([]);
  const [selectedQuantities, setSelectedQuantities] = useState({});
  const [selectedSerialNumbers, setSelectedSerialNumbers] = useState({});
  const [phoneNumbers, setPhoneNumbers] = useState({});
  const [shippingMethod, setShippingMethod] = useState('');
  const dispatch = useDispatch();
  const theme = useTheme();

  const returnObject = useSelector(
    (state) => state.returnObject?.value || { HEADER: {}, DETAIL: [] }
  );
  const returnItems = returnObject.DETAIL || [];

  const shippingMethods = [
    { id: 'standard', label: 'Standard Shipping' },
    { id: 'expedited', label: 'Expedited Shipping' },
    { id: 'overnight', label: 'Overnight Shipping' },
  ];

  // Group items by INVOICE_NUMBER
  const groupedReturnItems = returnItems.reduce((groups, item) => {
    const invoice = item.INVOICE_NUMBER || 'Unknown Invoice';
    if (!groups[invoice]) {
      groups[invoice] = [];
    }
    groups[invoice].push(item);
    return groups;
  }, {});

  useEffect(() => {
    axios.get('/returns/reasons').then((response) => {
      if (response.data.result === 'success') {
        setReasons(response.data.details);
      }
    });
  }, []);

  const handleCancelReturn = () => {
    dispatch(resetReturnObject());
    dispatch(resetReturnStep());
  };

  const formatPhoneNumber = (value) => {
    const digits = value.replace(/\D/g, '');
    if (digits.length === 0) return '';
    if (digits.length < 4) return `(${digits}`;
    if (digits.length < 7) return `(${digits.slice(0, 3)}) ${digits.slice(3)}`;
    return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
  };

  const handlePhoneChange = (invoice, value) => {
    setPhoneNumbers((prev) => ({
      ...prev,
      [invoice]: formatPhoneNumber(value),
    }));
  };

  const handleQuantityChange = (key, value) => {
    setSelectedQuantities((prev) => ({
      ...prev,
      [key]: value,
    }));
    setSelectedSerialNumbers((prev) => ({
      ...prev,
      [key]: Array(value).fill(''), // Reset serial number selection when quantity changes
    }));
  };

  const handleSerialNumberChange = (key, index, value) => {
    setSelectedSerialNumbers((prev) => {
      const updatedSerials = [...prev[key]];
      updatedSerials[index] = value;
      return {
        ...prev,
        [key]: updatedSerials,
      };
    });
  };

  return (
    <Container maxWidth='md' sx={{ mt: 4, mb: 4 }}>
      <Typography
        variant='h5'
        align='center'
        gutterBottom
        sx={{
          textAlign: 'center',
          letterSpacing: '0.05em',
          color: theme.palette.tsw.secondary,
          lineHeight: 1.2,
        }}
      >
        Claims Request Summary
      </Typography>

      {returnItems.length === 0 ? (
        <Typography align='center'>No items selected for return.</Typography>
      ) : (
        Object.entries(groupedReturnItems).map(([invoice, items]) => (
          <Card
            key={invoice}
            sx={{
              mb: 1,
              boxShadow: 3,
              borderRadius: 2,
              border: 2,
              borderColor: theme.palette.tsw.tertiary,
            }}
          >
            <CardHeader
              sx={{ mb: -1 }}
              title={
                <Box display='flex' flexDirection='column' gap={0}>
                  <Typography variant='subtitle1' sx={{ mb: 0 }}>
                    Invoice #{invoice}
                  </Typography>
                  {items.some((item) => Number(item.FULFILLMENT_ORDER) === 1) && (
                    <Typography
                      variant='subtitle2'
                      fontWeight='bold'
                      sx={{ color: (theme) => theme.palette.tsw.secondary }}
                    >
                      Fulfillment Order
                    </Typography>
                  )}
                </Box>
              }
            />
            <Divider sx={{ backgroundColor: theme.palette.tsw.tertiary }} />

            <CardContent>
              {items.map((item, index) => {
                const quantityKey = `${invoice}-${index}`;
                const isSerialized = item.SERIALIZED;
                const availableSerialNumbers = item.SERIAL_NUMBERS || [];

                return (
                  <Paper key={index} sx={{ p: 1, mb: 2 }}>
                    <Grid container spacing={2} alignItems='center'>
                      <Grid item xs={2}>
                        <Select
                          fullWidth
                          displayEmpty
                          variant='outlined'
                          value={selectedQuantities[quantityKey] || ''}
                          onChange={(e) => handleQuantityChange(quantityKey, e.target.value)}
                          renderValue={(selected) => selected || <em>Qty</em>}
                        >
                          <MenuItem value='' disabled>
                            <em>Qty</em>
                          </MenuItem>
                          {Array.from({ length: Number(item.QUANTITY) }, (_, i) => (
                            <MenuItem key={i + 1} value={i + 1}>
                              {i + 1}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography variant='subtitle1'>{item.ITEM_NUMBER}</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography variant='subtitle1' sx={{ textAlign: 'left' }}>
                          {item.ITEM_DESCRIPTION}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography variant='subtitle1'>
                          {convertFloatToCurrency(convertCurrencyToFloat(item.ITEM_PRICE))}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton>
                          <DeleteIcon sx={{ color: theme.palette.error.main }} />
                        </IconButton>
                      </Grid>

                      {/* Show Serial Number Selects if Serialized */}
                      {isSerialized && selectedQuantities[quantityKey] > 0 && (
                        <Grid item xs={12}>
                          <Typography variant='subtitle2'>Select Serial Numbers:</Typography>
                          <Grid container spacing={2} sx={{ mt: 1 }}>
                            {Array.from({ length: selectedQuantities[quantityKey] }).map((_, i) => (
                              <Grid item xs={6} key={i}>
                                <FormControl fullWidth variant='outlined'>
                                  <InputLabel>Select Serial Number</InputLabel>
                                  <Select
                                    value={selectedSerialNumbers[quantityKey]?.[i] || ''}
                                    onChange={(e) =>
                                      handleSerialNumberChange(quantityKey, i, e.target.value)
                                    }
                                    label='Select Serial Number'
                                  >
                                    {availableSerialNumbers.map((serial) => (
                                      <MenuItem
                                        key={serial}
                                        value={serial}
                                        disabled={selectedSerialNumbers[quantityKey]?.includes(
                                          serial
                                        )}
                                      >
                                        {serial}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Paper>
                );
              })}

              {/* Phone number input for fulfillment orders */}
              {items.some((item) => item.FULFILLMENT_ORDER === 1) && (
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={12}>
                    <TextField
                      label='End Consumer Phone Number'
                      variant='outlined'
                      fullWidth
                      value={phoneNumbers[invoice] || ''}
                      onChange={(e) => handlePhoneChange(invoice, e.target.value)}
                    />
                  </Grid>
                </Grid>
              )}
            </CardContent>
          </Card>
        ))
      )}

      {/* Shipping Method Select */}
      <Box sx={{ mt: 4, width: '50%' }} mx='auto'>
        <FormControl fullWidth variant='outlined'>
          <InputLabel id='shipping-method-label'>Shipping Method</InputLabel>
          <Select
            labelId='shipping-method-label'
            value={shippingMethod}
            onChange={(e) => setShippingMethod(e.target.value)}
            label='Shipping Method'
          >
            {shippingMethods.map((method) => (
              <MenuItem key={method.id} value={method.id}>
                {method.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Action Buttons */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, gap: 2 }}>
        <Button variant='contained' color='error' onClick={handleCancelReturn}>
          Cancel Claim Request
        </Button>
        <Button
          variant='contained'
          sx={{ backgroundColor: theme.palette.tsw.tertiary }}
          onClick={() => dispatch(prevReturnStep())}
        >
          Back to Item Select
        </Button>
        <Button variant='contained' color='success'>
          Submit Claim Request
        </Button>
      </Box>
    </Container>
  );
};

export default ReturnSummary;
