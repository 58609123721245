import { createSlice } from '@reduxjs/toolkit';

export const ReturnSlice = createSlice({
  name: 'returnObject',
  initialState: {
    value: { HEADER: {}, DETAIL: [] },
    returnStep: 1,
  },
  reducers: {
    setReturnObject: (state, action) => {
      state.value = { ...action.payload };
    },
    resetReturnObject: (state) => {
      state.value = { HEADER: {}, DETAIL: [] };
    },
    nextReturnStep: (state) => {
      state.returnStep += 1;
    },
    prevReturnStep: (state) => {
      state.returnStep -= 1;
    },
    resetReturnStep: (state) => {
      state.returnStep = 1;
    },
  },
});

export const {
  setReturnObject,
  resetReturnObject,
  nextReturnStep,
  prevReturnStep,
  resetReturnStep,
} = ReturnSlice.actions;
export default ReturnSlice.reducer;
