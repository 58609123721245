import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setReturnObject, nextReturnStep, prevReturnStep } from '../../../Redux/ReturnSlice';
import { Container, Grid, Box, Typography, TextField, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LoadingSpinner from '../../_GlobalComponents/LoadingSpinner';
import OrderAccordion from './OrderAccordion';

const PlacedOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedItems, setSelectedItems] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState('');
  const [visibleCount, setVisibleCount] = useState(10);
  const customerNumber = '510';
  const dispatch = useDispatch();
  const theme = useTheme();

  // Fetch orders on mount
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.post('/returns/getInvoices', { customerNumber });
        if (response.data.result === 'success') {
          setOrders(Object.values(response.data.details));
        }
      } catch (error) {
        console.error('Error fetching orders:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchOrders();
  }, [customerNumber]);

  // Handle checkbox changes
  const handleCheckboxChange = useCallback((order, item, event) => {
    const key = `${order.INVOICE_NUMBER}-${item.ITEM_NUMBER}`;
    const isChecked = event.target.checked;
    setSelectedItems((prev) => {
      const newSelected = { ...prev };
      if (isChecked) {
        newSelected[key] = {
          INVOICE_NUMBER: order.INVOICE_NUMBER,
          ITEM_NUMBER: item.ITEM_NUMBER,
          ITEM_DESCRIPTION: item.DESCRIPTION,
          ITEM_PRICE: item.PRICE,
          QUANTITY: item.QUANTITY,
          REASON_CODE: '',
          NOTES: [],
          IMAGES: [],
          SERIALIZED: Number(item.SERIALIZED) === 1,
          SERIAL_NUMBERS: Number(item.SERIALIZED) === 1 ? item.SERIAL_NUMBERS : [],
          FULFILLMENT_ORDER: Number(order.FULFILLMENT_ORDER) === 1 ? 1 : 0,
        };
      } else {
        delete newSelected[key];
      }
      return newSelected;
    });
  }, []);

  // Handle search input changes
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Debounce search query to avoid excessive re-renders
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(searchQuery);
    }, 300); // 300ms debounce delay

    return () => clearTimeout(handler);
  }, [searchQuery]);

  // Filter orders based on the debounced search query
  const filteredOrders = useMemo(() => {
    if (!debouncedQuery.trim()) return orders;
    const lowerQuery = debouncedQuery.toLowerCase();

    return orders.filter((order) => {
      if (order.INVOICE_NUMBER?.toLowerCase().includes(lowerQuery)) return true;
      if (!order.LINE_ITEMS) return false;

      return Object.values(order.LINE_ITEMS).some(
        (item) =>
          item.ITEM_NUMBER?.toLowerCase().includes(lowerQuery) ||
          item.DESCRIPTION?.toLowerCase().includes(lowerQuery)
      );
    });
  }, [orders, debouncedQuery]);

  // Slice after filtering to show only the visible count
  const visibleOrders = useMemo(
    () => filteredOrders.slice(0, visibleCount),
    [filteredOrders, visibleCount]
  );

  const handleShowMore = () => {
    setVisibleCount((prev) => prev + 10);
  };

  const handleContinue = useCallback(() => {
    dispatch(
      setReturnObject({
        HEADER: {
          CUSTOMER_NUMBER: customerNumber,
          NOTES: [],
          IMAGES: [],
        },
        DETAIL: Object.values(selectedItems),
      })
    );
    dispatch(nextReturnStep());
  }, [dispatch, customerNumber, selectedItems]);

  const handleBackToReturns = () => {
    dispatch(prevReturnStep());
  };

  return (
    <Container sx={{ mt: 0, mb: 4 }}>
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          backgroundColor: 'background.paper',
          zIndex: 1100,
          pb: 2,
          pt: 0,
          mb: 2,
          boxShadow: 1,
        }}
      >
        <Grid container spacing={0} alignItems='center' justifyContent='center' sx={{ px: 2 }}>
          <Grid item xs={12} textAlign='center'>
            <Typography
              variant='h5'
              sx={{
                textAlign: 'center',
                letterSpacing: '0.05em',
                color: theme.palette.tsw.secondary,
                lineHeight: 1.2,
              }}
            >
              Select Items for Claim
            </Typography>
          </Grid>
          {filteredOrders.length > 0 && (
            <Grid item xs={12} textAlign='center'>
              <Typography variant='subtitle1' sx={{ color: theme.palette.tsw.secondary }}>
                {filteredOrders.length} Eligible Orders
              </Typography>
            </Grid>
          )}
          <Grid item xs={3} textAlign='left'>
            <Button
              variant='contained'
              sx={{ backgroundColor: theme.palette.tsw.tertiary }}
              onClick={handleBackToReturns}
            >
              Back to Claims Status
            </Button>
          </Grid>
          <Grid item xs={6} textAlign='center'>
            <TextField
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder='Search orders...'
              variant='outlined'
              fullWidth
              disabled={loading}
            />
          </Grid>
          <Grid item xs={3} textAlign='right'>
            <Button
              variant='contained'
              sx={{ backgroundColor: theme.palette.tsw.tertiary }}
              onClick={handleContinue}
              disabled={Object.keys(selectedItems).length === 0}
            >
              Continue To Summary
            </Button>
          </Grid>
        </Grid>
      </Box>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <LoadingSpinner isLoading={loading} />
        </Box>
      ) : filteredOrders.length === 0 ? (
        <Typography>No matching orders found.</Typography>
      ) : (
        <>
          {visibleOrders.map((order) => (
            <Box key={order.INVOICE_NUMBER} sx={{ mb: 2 }}>
              <OrderAccordion
                order={order}
                selectedItems={selectedItems}
                onCheckboxChange={handleCheckboxChange}
              />
            </Box>
          ))}

          {visibleCount < filteredOrders.length && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Button
                variant='contained'
                onClick={handleShowMore}
                sx={{ backgroundColor: theme.palette.tsw.tertiary }}
              >
                Show More
              </Button>
            </Box>
          )}
        </>
      )}
    </Container>
  );
};

export default PlacedOrders;
