const convertFloatToCurrency = (value) => {
  if (typeof value !== 'number') {
    return value;
  }
  return value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};

const convertCurrencyToFloat = (value) => {
  if (typeof value !== 'string') {
    return value;
  }
  return parseFloat(value.replace(/[^0-9.-]+/g, ''));
};

export { convertFloatToCurrency, convertCurrencyToFloat };
