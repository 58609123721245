import React from 'react';
import { Grid, Typography, Checkbox } from '@mui/material';
import {
  convertCurrencyToFloat,
  convertFloatToCurrency,
} from '../../_GlobalFunctions/FormatCurrency';

// A memoized component for rendering an individual order line item.
const OrderLineItem = React.memo(({ order, item, isChecked, onCheckboxChange }) => (
  <Grid container spacing={1} alignItems='center' key={item.ITEM_NUMBER} sx={{ mb: 1 }}>
    <Grid item xs={1} display='flex' justifyContent='center'>
      <Checkbox checked={isChecked} onChange={(e) => onCheckboxChange(order, item, e)} />
    </Grid>
    <Grid item xs={1} display='flex' justifyContent='center'>
      <Typography>{item.QUANTITY}</Typography>
    </Grid>
    <Grid item xs={2} display='flex' justifyContent='center'>
      <Typography>{item.UPC}</Typography>
    </Grid>
    <Grid item xs={1} display='flex' justifyContent='center'>
      <Typography>{item.MPN}</Typography>
    </Grid>
    <Grid item xs={2} display='flex' justifyContent='center'>
      <Typography>{item.ITEM_NUMBER}</Typography>
    </Grid>
    <Grid item xs={4} display='flex' justifyContent='left'>
      <Typography>{item.DESCRIPTION}</Typography>
    </Grid>
    <Grid item xs={1} display='flex' justifyContent='center'>
      <Typography>{convertFloatToCurrency(convertCurrencyToFloat(item.PRICE))}</Typography>
    </Grid>
  </Grid>
));

export default OrderLineItem;
