import Grid2 from '@mui/material/Unstable_Grid2';
import React from 'react';
import PlacedOrders from './Components/PlacedOrders';
import ReturnSummary from './Components/ReturnSummary';
import { useSelector } from 'react-redux';
import ReturnStatus from './Components/ReturnStatus';

const Orders = () => {
  const returnObject = useSelector(
    (state) => state.returnObject?.value || { HEADER: {}, DETAIL: [] }
  );
  const returnStep = useSelector((state) => state.returnObject?.returnStep || 1);

  const returnItems = returnObject.DETAIL || [];

  return (
    <>
      {returnStep === 1 ? (
        <ReturnStatus />
      ) : returnStep === 2 ? (
        <PlacedOrders />
      ) : returnStep === 3 ? (
        <ReturnSummary />
      ) : (
        <></>
      )}
    </>
  );
};

export default Orders;
