import { Box, Button, Divider, Grid, Typography, Paper } from '@mui/material';
import {
  MaterialReactTable,
  MRT_ToggleFullScreenButton as MrtFullScreenToggleButton,
  MRT_ToggleDensePaddingButton as MrtToggleDensePaddingButton,
  MRT_ToggleFiltersButton as MrtToggleFiltersButton,
  MRT_ToggleGlobalFilterButton as MrtToggleGlobalFilterButton,
} from 'material-react-table';
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { nextReturnStep } from '../../../Redux/ReturnSlice';

const ReturnStatus = () => {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    axios.post('/returns/getHeaders', { customerNumber: '510' }).then((response) => {
      if (response.data.result === 'success') {
        setData(response.data.details);
      }
    });
  }, []);

  const columns = [
    {
      header: 'RMA #',
      accessorKey: 'RMA_ID',
      size: 150,
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'Date Created',
      accessorKey: 'RMA_CREATED',
      size: 150,
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{dayjs(renderedCellValue).format('MM/DD/YYYY')}</Typography>
        </Box>
      ),
    },
    {
      header: 'Status',
      accessorKey: 'STATUS_DESC',
      size: 150,
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
  ];

  return (
    <>
      <Box>
        <MaterialReactTable
          columns={columns}
          data={data}
          enableHiding={false}
          initialState={{ showColumnFilters: false }}
          positionToolbarAlertBanner='bottom'
          enableStickyHeader
          muiTableContainerProps={{ sx: { maxHeight: '63vh' } }}
          muiTableHeadCellProps={({ column }) => ({
            sx: {
              fontSize: '1rem',
            },
          })}
          muiDetailPanelProps={({ row }) => ({
            sx: {
              borderLeft: row.getIsExpanded() ? `2px solid ${theme.palette.tsw.tertiary}` : 'none',
              borderRight: row.getIsExpanded() ? `2px solid ${theme.palette.tsw.tertiary}` : 'none',
              borderBottom: row.getIsExpanded()
                ? `2px solid ${theme.palette.tsw.tertiary}`
                : 'none',
            },
          })}
          muiTableBodyCellProps={({ row }) => ({
            sx: {
              borderTop: row.getIsExpanded() ? `2px solid ${theme.palette.tsw.tertiary}` : 'none',
              borderBottom: row.getIsExpanded()
                ? `2px solid ${theme.palette.tsw.tertiary}`
                : 'none',
              backgroundColor: row.getIsExpanded() ? theme.palette.tsw.tertiary : 'white',
              color: row.getIsExpanded() ? 'white' : 'black',
              '&:last-child': {
                borderRight: row.getIsExpanded()
                  ? `2px solid ${theme.palette.tsw.tertiary}`
                  : 'none',
              },
              '&:first-child': {
                borderLeft: row.getIsExpanded()
                  ? `2px solid ${theme.palette.tsw.tertiary}`
                  : 'none',
              },
            },
          })}
          renderToolbarInternalActions={({ table }) => {
            return (
              <Box>
                <Button
                  variant='contained'
                  sx={{
                    backgroundColor: theme.palette.tsw.tertiary,
                    fontSize: '1rem',
                    fontWeight: 600,
                    whiteSpace: 'pre',
                    mr: 2,
                  }}
                  onClick={() => dispatch(nextReturnStep())}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      sx={{
                        letterSpacing: '0.05em',
                        fontWeight: 'bold',
                        textTransform: 'none',
                      }}
                    >
                      Start New Claims Request
                    </Typography>
                  </Box>
                </Button>
                <MrtToggleGlobalFilterButton table={table} />
                <MrtToggleFiltersButton table={table} />
                <MrtToggleDensePaddingButton table={table} />
                <MrtFullScreenToggleButton table={table} />
              </Box>
            );
          }}
          renderTopToolbarCustomActions={({ table }) => {
            return (
              <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                width='65%'
                flexDirection='column'
              >
                <Box display='flex' flexDirection='row' width='100%' justifyContent='flex-start'>
                  <Typography
                    variant='h5'
                    sx={{
                      textAlign: 'center',
                      letterSpacing: '0.05em',
                      color: theme.palette.tsw.secondary,
                      lineHeight: 1.2,
                    }}
                  >
                    Claims Requests
                  </Typography>
                </Box>
              </Box>
            );
          }}
          renderDetailPanel={({ row }) => {
            return (
              <Box sx={{ p: 2 }}>
                {row.original.DETAILS.map((item) => (
                  <Paper
                    key={item.RMA_LINE_ID}
                    elevation={2}
                    sx={{
                      mb: 2,
                      p: 2,
                      backgroundColor: '#fafafa',
                      borderRadius: '8px',
                      border: '1px solid',
                      borderColor: theme.palette.tsw.tertiary,
                    }}
                  >
                    {/* Duplicated header row for this item */}
                    <Grid container spacing={2}>
                      <Grid item xs={2} container justifyContent='flex-start'>
                        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                          Invoice #
                        </Typography>
                      </Grid>
                      <Grid item xs={2} container justifyContent='flex-start'>
                        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                          Item #
                        </Typography>
                      </Grid>
                      <Grid item xs={1} container justifyContent='flex-end'>
                        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                          Qty
                        </Typography>
                      </Grid>
                      <Grid item xs={3} container justifyContent='flex-end'>
                        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                          Reason
                        </Typography>
                      </Grid>
                      <Grid item xs={2} container justifyContent='flex-end'>
                        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                          Image Link
                        </Typography>
                      </Grid>
                      <Grid item xs={2} container justifyContent='flex-end'>
                        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                          Image Description
                        </Typography>
                      </Grid>
                    </Grid>

                    <Divider sx={{ width: '100%', my: 1 }} />

                    {/* Main row for the item details */}
                    <Grid container spacing={2} sx={{ mb: 1 }}>
                      <Grid item xs={2} container justifyContent='flex-start'>
                        <Typography variant='body1'>{item.INVOICE_NUMBER}</Typography>
                      </Grid>
                      <Grid item xs={2} container justifyContent='flex-start'>
                        <Typography variant='body1'>{item.ITEM_NUMBER}</Typography>
                      </Grid>
                      <Grid item xs={1} container justifyContent='flex-end'>
                        <Typography variant='body1'>{item.QUANTITY}</Typography>
                      </Grid>
                      <Grid item xs={3} container justifyContent='flex-end'>
                        <Typography variant='body1'>{item.RMA_REASON}</Typography>
                      </Grid>
                      {item.IMAGES && item.IMAGES.length > 0 ? (
                        <>
                          <Grid item xs={2} container justifyContent='flex-end'>
                            <Typography variant='body1'>{item.IMAGES[0].IMAGE_LINK}</Typography>
                          </Grid>
                          <Grid item xs={2} container justifyContent='flex-end'>
                            <Typography variant='body1'>{item.IMAGES[0].IMAGE_NOTE}</Typography>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item xs={2} container justifyContent='flex-end'>
                            <Typography variant='body1'>No Images</Typography>
                          </Grid>
                          <Grid item xs={2} container justifyContent='flex-end'>
                            <Typography variant='body1'>No Images</Typography>
                          </Grid>
                        </>
                      )}
                    </Grid>

                    {/* Additional image rows if more than one image exists */}
                    {item.IMAGES &&
                      item.IMAGES.length > 1 &&
                      item.IMAGES.slice(1).map((image) => (
                        <Grid container spacing={2} key={image.RMA_D_IMAGE_ID} sx={{ mb: 1 }}>
                          {/* Empty cells for alignment */}
                          <Grid item xs={2} container justifyContent='flex-start' />
                          <Grid item xs={2} container justifyContent='flex-start' />
                          <Grid item xs={1} container justifyContent='flex-end' />
                          <Grid item xs={3} container justifyContent='flex-end' />
                          <Grid item xs={2} container justifyContent='flex-end'>
                            <Typography variant='body1'>{image.IMAGE_LINK}</Typography>
                          </Grid>
                          <Grid item xs={2} container justifyContent='flex-end'>
                            <Typography variant='body1'>{image.IMAGE_NOTE}</Typography>
                          </Grid>
                        </Grid>
                      ))}

                    {/* Conditionally render the Notes Section only if notes exist */}
                    {item.NOTES && item.NOTES.length > 0 && (
                      <Box sx={{ mt: 2 }}>
                        <Typography variant='subtitle2' sx={{ fontWeight: 'bold', mb: 1 }}>
                          Comments:
                        </Typography>
                        {item.NOTES.map((note) => (
                          <Box
                            key={note.RMA_D_NOTE_ID}
                            sx={{
                              mb: 1,
                              p: 1,
                              borderLeft: '2px solid gray',
                              pl: 1,
                              backgroundColor: '#f9f9f9',
                              borderRadius: '4px',
                            }}
                          >
                            <Typography variant='body2' sx={{ whiteSpace: 'pre-wrap' }}>
                              {note.NOTE}
                            </Typography>
                            <Typography variant='caption' color='text.secondary'>
                              {note.NOTE_BY} - {note.NOTE_CREATED}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </Paper>
                ))}
              </Box>
            );
          }}
          muiExpandButtonProps={({ row, table }) => ({
            onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //set only this row to be expanded
            sx: {
              color: row.getIsExpanded() ? 'white' : 'gray',
            },
          })}
        />
      </Box>
    </>
  );
};

export default ReturnStatus;
